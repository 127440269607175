import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import ModalOneContent from "./modal/ModalOneContent";
import ModalTwoContent from "./modal/ModalTwoContent";
import ModalThreeContent from "./modal/ModalThreeContent";
import ModalFourContent from "./modal/ModalFourContent";
import ModalFiveContent from "./modal/ModalFiveContent";
import ModalSixContent from "./modal/ModalSixContent";
import ModalSevenContent from "./modal/ModalSevenContent";
import ModalEightContent from "./modal/ModalEightContent";
import ModalNineContent from "./modal/ModalNineContent";
import {Link} from "react-router-dom";

const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }
  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }
  function toggleModalEight() {
    setIsOpen8(!isOpen8);
  }
  function toggleModalNine() {
    setIsOpen9(!isOpen9);
  }

  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>YII2</Tab>
        <Tab>LARAVEL</Tab>
        <Tab>CODEIGNITER</Tab>
        <Tab>WORDPRESS PLUGIN</Tab>
        <Tab>REACT JS</Tab>
        <Tab>VUEJS</Tab>
        <Tab>NEXT JS</Tab>
        <Tab>THEMEFOREST PRODUCT</Tab>
        <Tab>OUTSIDE</Tab>
      </TabList>

      <div className="portfolio-tab-content">

        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/yii2/inventory/2.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">INVENTORY Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>INVENTORY Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">INVENTORY APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BANGLADESH</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://dev.sellerwiz.io/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://dev.sellerwiz.io/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/yii2/inventory/6.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/yii2/ecomerce/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> ECOMMERCE Project</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                  isOpen={isOpen2}
                  onRequestClose={toggleModalTwo}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>ECOMERCE Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">ECOMMERCE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">PLUSPOINT</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://admin.pluspointfashion.com/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                https://admin.pluspointfashion.com/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/yii2/ecomerce/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/yii2/inland/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> Inland Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>INLAND (GOV) Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">INLAND (GOV)</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">GOVERNMENT</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://dosinland.dos.gov.bd/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://dosinland.dos.gov.bd/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/yii2/inland/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>


            {/* <!-- Portfolio Item Ends --> */}

            {/* <!--4  Portfolio Item Starts --> */}
            <li
                className="direction-reveal"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure
                  className="direction-reveal__card"
                  onClick={toggleModalFour}
              >
                <img src="img/yii2/mycarrears/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> JOB PORTAL Project</span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                  isOpen={isOpen4}
                  onRequestClose={toggleModalFour}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>JOB PORTAL Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">JOB PORTAL</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BD</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://www.mycareerbugs.com/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                              https://www.mycareerbugs.com/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/yii2/mycarrears/3.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                className="direction-reveal"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure
                  className="direction-reveal__card"
                  onClick={toggleModalFive}
              >
                <img src="img/yii2/mmderp/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> MMDERP Project</span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                  isOpen={isOpen5}
                  onRequestClose={toggleModalFive}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFive}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>JOB PORTAL Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">MDDERP</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">GOVERNMENT</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                Setup In local
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/yii2/mmderp/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                className="direction-reveal"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure
                  className="direction-reveal__card"
                  onClick={toggleModalSix}
              >
                <img src="img/yii2/joinnavy/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> JOIN NAVY Project</span>
                </div>
              </figure>

              {/* Start ModalSixContent */}
              <Modal
                  isOpen={isOpen6}
                  onRequestClose={toggleModalSix}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSix}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">

                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>JOIN NAVY  Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">JOIN NAVY</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">GOVERNMENT</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://joinnavy.navy.mil.bd"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://joinnavy.navy.mil.bd
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/yii2/mmderp/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  ModalSixContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}
          </ul>
        </TabPanel>
        {/* End Logo Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
            {/* <!-- Portfolio Item Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/laravel/bid/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> BIDOUT Project</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModalTwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>INVENTORY Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">BIDOUT APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">THEMFOREST</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">LARAVEL, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                LOCALLY HAS SETUP
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/laravel/bid/1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFour}
              >
                <img src="img/laravel/entertainment/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> Entertainment Project</span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                isOpen={isOpen4}
                onRequestClose={toggleModalFour}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>INVENTORY Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">Entertainment APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BANGLADESH</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://entertainmentbugs.com/dev2022/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://entertainmentbugs.com/dev2022/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/laravel/entertainment/2.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/laravel/multitenant/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> MULTITENATN Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>MULTITENATN Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">MULTITENATN</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE BD</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">LARAVEL, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                              LIVE URL HAS DOWN
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/laravel/multitenant/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>


            {/* <!-- Portfolio Item Ends --> */}

            {/* <!--4  Portfolio Item Starts --> */}
            <li
                className="direction-reveal"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure
                  className="direction-reveal__card"
                  onClick={toggleModalFour}
              >
                <img src="img/laravel/paysenz/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> PAYMENT GATEWAY Project</span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                  isOpen={isOpen4}
                  onRequestClose={toggleModalFour}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>PAYMENT GATEWAY PORTAL Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">PAYMENT GATEWAY PORTAL</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">IN HOUSE(UNLOCKLIVE)</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">LARAVEL,VUEJS ,JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                             SERVER HAS DOWN
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/laravel/paysenz/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>


          </ul>
        </TabPanel>

        {/* End Video Project */}

        <TabPanel>
          <ul className="row grid justify-content-center">
            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEight}
              >
                <img src="img/codegnator/exam/1.png" alt="Portolio" />

                <div className=" hover-content-wrapper">
                  <span className="content-title">Examinations Applications</span>
                </div>
              </figure>
              {/* Start ModalEightContent */}
              <Modal
                isOpen={isOpen8}
                onRequestClose={toggleModalEight}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEight}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>INVENTORY Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">EXAMINATIONS APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BANGLADESH</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">CODEIGNITER, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://eclass.competitiveexamguide.com/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                              https://eclass.competitiveexamguide.com/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/codegnator/exam/1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalEightContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalNine}
              >
                <img src="img/codegnator/mlm/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> MLM Project</span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen9}
                onRequestClose={toggleModalNine}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalNine}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>MLM Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">MLM APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BANGLADESH</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">CODEIGNITER, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://crp.advaitnandeshwar.in/Login"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://crp.advaitnandeshwar.in
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/codegnator/mlm/1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/codegnator/supereditor/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> ERP Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>ERP Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">ERP</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE BD</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">CODEIGNITER, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://erp.supereditors.in/kavya/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://erp.supereditors.in/kavya/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/codegnator/supereditor/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>

          </ul>
        </TabPanel>
        {/* End Graphic Design Project*/}

        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/plugin/notificaiton/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">NOTIFICAITON Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>NOTIFICAITON Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">NOTIFICAITON APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">BANGLADESHi</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">WORDPRESS, PHP, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://plugin.zenstech.net/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                              https://plugin.zenstech.net/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/plugin/notificaiton/2.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/plugin/Lakehoods/2.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> PROPERTY BOOKING Project</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                  isOpen={isOpen2}
                  onRequestClose={toggleModalTwo}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>PROPERTY BOOKING  Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">PROPERTY BOOKING </span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">PROPERTY BOOKING</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">WORDPRESS, PHP , JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://plugin.zenstech.net/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                https://plugin.zenstech.net/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/plugin/Lakehoods/2.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/plugin/StripePaymentGateway/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">STRIPE GATEWAY Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>STRIPE GATEWAY Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">STRIPE GATEWAY</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BD</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">WORDPRESS , PHP , JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://plugin.zenstech.net/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               https://plugin.zenstech.net/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/plugin/StripePaymentGateway/2.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>


            {/* <!-- Portfolio Item Ends --> */}

            {/* <!--4  Portfolio Item Starts --> */}
            <li
                className="direction-reveal"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure
                  className="direction-reveal__card"
                  onClick={toggleModalFour}
              >
                <img src="img/plugin/SliderSettings/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> EASY SLIDERS Project</span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                  isOpen={isOpen4}
                  onRequestClose={toggleModalFour}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>EASY SLIDERS Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">EASY SLIDERS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">PERSONAL</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">WORDPRESS, PHP, YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                             Personal
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/plugin/SliderSettings/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                className="direction-reveal"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure
                  className="direction-reveal__card"
                  onClick={toggleModalFive}
              >
                <img src="img/plugin/MemberManagementRexwa/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">   MEMBER MANAGEMENT Project</span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                  isOpen={isOpen5}
                  onRequestClose={toggleModalFive}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFive}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>JOB PORTAL Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase"> MEMBER MANAGEMENT</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">REXWA</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">YII2, JQUERY, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               MEMBER MANAGEMENT
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/plugin/MemberManagementRexwa/6.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}
          </ul>
        </TabPanel>

        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/react-js/pluspoint/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">ECOMMERCE Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>ECOMMERCE Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">ECOMMERCE APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OFFICE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">REACT , HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                              LOCALHOST
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/react-js/pluspoint/2.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/react-js/mothercare/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> ECOMMERCE Project</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                  isOpen={isOpen2}
                  onRequestClose={toggleModalTwo}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>ECOMMERCE  Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">ECOMMERCE </span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">MOTHERCARE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">REACT JS, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                localhost
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/react-js/mothercare/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/react-js/agencies/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">WEBSITE Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>WEBSITE Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">WEBSITE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OFFICE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">REACT JS,  HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://plugin.zenstech.net/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                             localhost
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/react-js/agencies/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>
          </ul>
        </TabPanel>

        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/vuejs/ecomerce/2.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">ECOMMERCE Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>ECOMMERCE Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">ECOMMERCE APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BD</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">VUEJS ,VUEX ORM , CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://indi-taco.com/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                             https://indi-taco.com/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/vuejs/ecomerce/1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}
          </ul>
        </TabPanel>

        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/nextjs/naftreand/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">WEBSITE DESIGN Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>WEBSITE DESIGN Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">WEBSITE DESIGN</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OUTSIDE OF BD</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">NEXTJS , HTML , CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                              LOCALHOST
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/nextjs/naftreand/1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/nextjs/nicklipas/n1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">Subscriptions Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>Subscriptions Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">Subscriptions Portal</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">OFFICE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">NEXT JS,Stripe,HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://plugin.zenstech.net/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                             localhost
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/nextjs/nicklipas/n2.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>

            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/nextjs/rental/rental1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> Rental Project</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                  isOpen={isOpen2}
                  onRequestClose={toggleModalTwo}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>Rental  Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">Rental </span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">Official</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">NEXT JS,Yii2, REACT BOOTSTRAP, SCSS,CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="https://allmyrental-next.vercel.app/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                https://allmyrental-next.vercel.app/
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/nextjs/rental/rental1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>

          </ul>
        </TabPanel>

        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/themeforest/react/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">THEMEFOREST Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>THEMEFOREST Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">THEMEFOREST APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">THEMEFOREST</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">REACT , HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                                RESTRICTED
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/themeforest/react/1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/themeforest/plugin/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> THEMEFOREST Project</span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                  isOpen={isOpen2}
                  onRequestClose={toggleModalTwo}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>THEMEFOREST  Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">THEMEFOREST </span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">THEMEFOREST</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">WORDPRESS, PHP, JS, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >RESTRICTED
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/themeforest/plugin/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/themeforest/nextjs/1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">WEBSITE Project</span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                  isOpen={isOpen3}
                  onRequestClose={toggleModalThree}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>WEBSITE Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">WEBSITE</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">THEMEFOREST</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">NEXT JS, HTML, CSS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="#"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                             RESTRICTED
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <div className="videocontainer">
                          <img src="img/themeforest/nextjs/1.png" alt="Portolio" />
                        </div>
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>

                  </div>
                </div>
              </Modal>
              {/* End  3 ModalThreeContent */}
            </li>
          </ul>
        </TabPanel>


        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/php/c1.png" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title">RAW PHP Project</span>
                </div>
              </figure>

              {/* Start ModalOneContent */}
              <Modal
                  isOpen={isOpen}
                  onRequestClose={toggleModalOne}
                  contentLabel="My dialog"
                  className="custom-modal dark"
                  overlayClassName="custom-overlay dark"
                  closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <div className="slideshow">
                      <figure>
                        {/* Project Details Starts */}
                        <figcaption>
                          <h3>RAW PHP Project</h3>
                          <div className="row open-sans-font">
                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-file-text-o pr-2"></i>
                              <span className="project-label">Project </span>:{" "}
                              <span className="ft-wt-600 uppercase">RAW PHP APPLICATIONS</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-user-o pr-2"></i>
                              <span className="project-label">Client </span>:{" "}
                              <span className="ft-wt-600 uppercase">RAW PHP</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-code pr-2"></i>
                              <span className="project-label">Language </span>:{" "}
                              <span className="ft-wt-600 uppercase">php , HTML, CSS , JQUERY</span>
                            </div>
                            {/* End .col */}

                            <div className="col-12 col-sm-6 mb-2">
                              <i className="fa fa-external-link pr-2"></i>
                              <span className="project-label">Preview </span>:{" "}
                              <span className="ft-wt-600 uppercase">
                              <a
                                  href="http://otodo.zenstech.net/"
                                  target="_blank"
                                  rel="noreferrer"
                              >
                               http://otodo.zenstech.net/  username:1150  password:abcd1234
                              </a>
                            </span>
                            </div>
                            {/* End .col */}
                          </div>
                          {/* End .row */}
                        </figcaption>
                        {/* Project Details Ends */}

                        {/*  Main Project Content Starts */}
                        <img src="img/php/c1.png" alt="Portolio" />
                        {/* Main Project Content Ends */}
                      </figure>
                    </div>
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
          </ul>
        </TabPanel>

        <div      style={{textAlign:"center",marginTop:"20px"}}>
         <a target="_blank" href="https://bitbucket.org/Zahid08/workspace/repositories">
            <button className="button" onClick={toggleModalOne}>
              <span className="button-text">More Project</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button>
         </a>

        </div>


      </div>
    </Tabs>
  );
};

export default Portfolio;
