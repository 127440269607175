import React from "react";

const experienceContent = [
  {
    year: "MAY 2023 -  Continue",
    position: "Full Stack Web Developer",
    compnayName: "Apex Property, Dhaka, Bangladesh.",
    details: `Apex Property is a Software Development Company.I am playing role for designing and developing software`,
  },
  {
    year: "Aug 2018 -  May 2023",
    position: "Sr. Software Engineer(Team leader)",
    compnayName: "Unlocklive IT",
    details: `UnlockLive IT Limited provides state-of-the-art software solutions to clients all over the world.`,
  },
  {
    year: "JAN 2018 - SEP 2018",
    position: "Full Stack Web Developer",
    compnayName: "Mewoc Technologies, Hinjewadi,Pune, India.",
    details: `IT Support & Services | Software Development | ecommerce Development | EdTech Solutions | ERP & CRM | Digital Branding `,
  },
  {
    year: "April 2017 - Dec 2017",
    position: "Software Engineer",
    compnayName: "Brotherhood Infotech",
    details: `Brotherhood Infotech has a couple of furnished products. Also, we do Web & Mobile apps development and server hosting for our clients.`,
  }
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
